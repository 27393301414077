<template>
  <ul class="u-list">
    <slot />
  </ul>
</template>

  <style lang="scss">
  .u-list {
    @apply py-2 min-w-48 bg-white dark:bg-neutral-dark-700;
  }
  </style>
